<template>
  <div>
    <v-snackbar color="red" top v-model="snackbar" :timeout="timeout" content-class="text--center">
      <span class="d-flex justify-center"> Erro! Mensagem: {{ error }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn color="accent" text v-bind="attrs" @click="snackbar = false">
          FECHAR
        </v-btn>
      </template>
    </v-snackbar>
    <router-view :denuncias="denuncias" :usuarios="usuarios" :loading="loading" :loading2="loading2" :userData="userData"
      @update="update" />
  </div>
</template>
<script>
import request from "@/router/requests.js";
import { isAdmin } from '@/auth'

export default {
  data() {
    return {
      denuncias: [],
      usuarios: [],
      loading: false,
      loading2: false,
      snackbar: false,
      error: "",
      timeout: 10000,
      userData: {},
    };
  },
  computed: {
    isAdmin() {
      return isAdmin ? true : false
    }
  },
  methods: {
    async update() {
      await request("GET", "denuncias?populate=*").then((x) => {
        if (x.error) {
          this.error = x.error.message;
          this.snackbar = true;
          this.loading = false;
        }
        this.loading = false;
        this.denuncias = x.data;
      });
    }
  },
  async mounted() {
    this.userData = JSON.parse(localStorage.getItem("userData"));
    this.loading = true;
    this.loading2 = true;
    // CHECA SE USUARIO É ADMIN MODERADOR
    if (isAdmin) {
      await request("GET", "denuncias?populate=*").then((x) => {
        if (x.error) {
          this.error = x.error.message;
          this.snackbar = true;
          this.loading = false;
        }
        this.loading = false;
        this.denuncias = x.data;
      });
      await request("GET", "users?populate=*").then((x) => {
        this.loading2 = false;
        this.usuarios = x;
      });
    }

  }
};
</script>